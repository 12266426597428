import React from 'react';

function BlogFrame({ children, infoproductImageUrl, infoproductRedirectUrl, rightInfoproductImageUrl, rightInfoproductRedirectUrl }) {
  return (
    <div className="flex flex-col lg:flex-row max-w-6xl mx-auto px-3 py-8 space-y-4 lg:space-y-0 lg:space-x-6">
      <aside className="lg:w-44 p-4 bg-gray-100 rounded-lg shadow-lg flex justify-center items-center">
        {/* Mostrar la imagen del infoproducto con botón de redirección */}
        {infoproductImageUrl && (
          <div className="flex flex-col items-center text-center">
            <img 
              src={infoproductImageUrl} 
              alt="Infoproducto" 
              className="w-full h-auto rounded-lg" 
            />
            <a 
              href={infoproductRedirectUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="mt-4 inline-block px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Ver más
            </a>
          </div>
        )}
      </aside>

      <div className="flex-1 bg-white p-8 rounded-lg shadow-lg lg:mx-5">
        {children}
      </div>

      <aside className="lg:w-44 p-4 bg-gray-100 rounded-lg shadow-lg hidden lg:flex justify-center items-center">
         {/* Mostrar la imagen del infoproducto con botón de redirección */}
         {rightInfoproductImageUrl && (
          <div className="flex flex-col items-center text-center">
            <img 
              src={rightInfoproductImageUrl} 
              alt="Infoproducto" 
              className="w-full h-auto rounded-lg" 
            />
            <a 
              href={rightInfoproductRedirectUrl} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="mt-4 inline-block px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Ver más
            </a>
          </div>
        )}
      </aside>
    </div>
  );
}

export default BlogFrame;
