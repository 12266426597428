import React, { useState } from 'react';
import NewLandingPage from '../../components/BlogsNews/MetadataForm';
import GrapesEditor from '../../components/BlogsNews/GrapesEditor';





function NewsletterCreator() {
  

  return (
    <>

 

    <GrapesEditor/>



    </>
  );
}

export default NewsletterCreator;
