const loadTestimonialsBlock = (editor) => {
  editor.BlockManager.add('Testimonials Block', {
    label: 'Testimonials Block',
    content: `
      <section class="testimonials-block" style="padding: 50px 20px; background: #f9f9f9;">
        <div style="text-align: center; margin-bottom: 30px;">
          <h3 style="font-size: 2em;">What Our Clients Say</h3>
          <p style="color: #777;">Testimonials from our satisfied customers</p>
        </div>
        <div class="testimonials-container" style="display: flex; justify-content: space-around; gap: 20px; flex-wrap: wrap;">
          <div class="testimonial-item" style="flex: 1 1 30%; padding: 20px; background: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 250px;">
            <p style="color: #777;">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim."</p>
            <h4 style="font-size: 1.2em; margin-top: 10px;">- John Doe</h4>
          </div>
          <div class="testimonial-item" style="flex: 1 1 30%; padding: 20px; background: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 250px;">
            <p style="color: #777;">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim."</p>
            <h4 style="font-size: 1.2em; margin-top: 10px;">- Jane Smith</h4>
          </div>
          <div class="testimonial-item" style="flex: 1 1 30%; padding: 20px; background: white; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 250px;">
            <p style="color: #777;">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim."</p>
            <h4 style="font-size: 1.2em; margin-top: 10px;">- Bob Johnson</h4>
          </div>
        </div>
      </section>
      <style>
        @media (max-width: 768px) {
          .testimonials-container {
            flex-direction: column;
            align-items: center;
          }
          .testimonial-item {
            width: 100%;
            max-width: 400px;
          }
        }
        @media (max-width: 480px) {
          .testimonials-block h3 {
            font-size: 1.8em;
          }
          .testimonials-block p {
            font-size: 1em;
          }
          .testimonial-item h4 {
            font-size: 1.1em;
          }
        }
      </style>
    `,
  });
};

export default loadTestimonialsBlock;
