import React, { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/index';
import 'grapesjs-preset-newsletter';
import { useNavigate, useParams } from 'react-router-dom';
import loadBlogBlocks from './blocks/loadBlogBlocks'

const GrapesEditor = () => {
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (!editorRef.current) return;

    const editor = grapesjs.init({
      container: editorRef.current,
      fromElement: true,
      height: '100vh',
      width: 'auto',
      storageManager: {
        type: 'remote',
        stepsBeforeSave: 1,
        urlStore: 'https://modelo-de-negocios-backend-180g.onrender.com/save/blogs',
        params: {},
        headers: {},
      },
     
      store: async (data, options) => {
        const response = await fetch(options.urlStore, {
          method: 'POST',
          headers: options.headers,
          body: JSON.stringify({ content: data }),
        });
        if (!response.ok) throw new Error('Failed to save');
      },
      canvas: {
        styles: ['https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css'],
      },
      styleManager: {
        sectors: [{
          name: 'General',
          buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom'],
      },{
          name: 'Dimension',
          open: false,
          buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
      },{
          name: 'Typography',
          open: false,
          buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align', 'text-decoration', 'text-shadow'],
      },{
          name: 'Decorations',
          open: false,
          buildProps: ['background-color', 'border-radius', 'border', 'box-shadow', 'background'],
      },{
          name: 'Extra',
          open: false,
          buildProps: ['opacity', 'transition', 'perspective', 'transform'],
      }],
      },
      
    });

    loadBlogBlocks(editor);

  
  

    editor.on('load', () => {
      const html = editor.getHtml();
      const css = editor.getCss();
    });

    editor.Panels.addButton('options', [
      {
        id: 'save-db',
        className: 'fa fa-floppy-o',
        command: 'save-db',
        attributes: { title: 'Save DB' },
      },
    ]);

    editor.Commands.add('save-db', {
      run: (editor, sender) => {
        sender && sender.set('active', 0);
        const html = editor.getHtml();
        console.log(html);
        const css = editor.getCss();
        console.log(css);
        const content = { html, css };
        setTimeout(() => handleSubmit(content), 100);
      },
    });

    

    return () => {
      
    };
  }, []);

  const handleSubmit = async (content) => {
    const { html, css } = content;

    const cleanHtml = html.replace(/\\/g, '');
    const cleanCss = css.replace(/\\"/g, '"');

    const data = {
      html: cleanHtml,
      css: cleanCss,
      lastUpdated: new Date().toISOString().split('T')[0],
    };

    try {
      const response = await fetch('https://modelo-de-negocios-backend-180g.onrender.com/save/blogs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Error al guardar el contenido');
      }

      const responseData = await response.json();
      console.log("Data to be submitted:", data);
      console.log('Document ID received:', responseData._id);

      navigate(`/blogmeta/${responseData._id}`);
    } catch (error) {
      console.log(error.message);
    }
  };

  

  return (
   
        <div ref={editorRef} ></div>
    
  );
};

export default GrapesEditor;
