import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import 'tailwindcss/tailwind.css'; // Asegúrate de tener Tailwind configurado

function Acerca() {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-indigo-100 via-purple-50 to-indigo-50 font-poppins">
      <Header />

      <section className="flex-grow py-16 mt-8">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <h1 className="text-6xl font-extrabold text-center  mb-12 tracking-wide">
            Sobre Nosotros
          </h1>

          <div className="space-y-16">

            {/* Misión */}
            <div className="bg-white shadow-2xl rounded-3xl p-10 transition transform hover:scale-105 hover:shadow-3xl">
              <h2 className="text-4xl font-bold  mb-6 text-center uppercase tracking-wider">
                Misión
              </h2>
              <p className="text-lg text-gray-700 leading-relaxed text-justify">
                Nuestra misión es capacitar a los futuros líderes en negocios digitales con herramientas estratégicas
                y tecnológicas de vanguardia, preparándolos para liderar en un mercado global en constante evolución.
              </p>
            </div>

            {/* Visión */}
            <div className="bg-white shadow-2xl rounded-3xl p-10 transition transform hover:scale-105 hover:shadow-3xl">
              <h2 className="text-4xl font-bold  mb-6 text-center uppercase tracking-wider">
                Visión
              </h2>
              <p className="text-lg text-gray-700 leading-relaxed text-justify">
                Ser reconocidos como la institución educativa líder en la formación de profesionales altamente calificados,
                fomentando la innovación, el liderazgo y la responsabilidad en el ámbito digital.
              </p>
            </div>

            {/* Valores */}
            <div className="bg-white shadow-2xl rounded-3xl p-10 transition transform hover:scale-105 hover:shadow-3xl">
              <h2 className="text-4xl font-bold  mb-6 text-center uppercase tracking-wider">
                Valores
              </h2>
              <ul className="list-disc pl-8 text-lg text-gray-700 leading-relaxed space-y-3">
                <li>
                  <span className="font-semibold ">Innovación:</span> Buscamos soluciones disruptivas que mejoren continuamente el entorno digital.
                </li>
                <li>
                  <span className="font-semibold ">Responsabilidad:</span> Promovemos una ética sólida y prácticas sostenibles en cada decisión.
                </li>
                <li>
                  <span className="font-semibold ">Colaboración:</span> Fomentamos la cooperación para potenciar los resultados.
                </li>
                <li>
                  <span className="font-semibold ">Liderazgo:</span> Formamos líderes que inspiren cambios positivos en la sociedad.
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Acerca;
