const loadImageRight = (editor) => {
    editor.BlockManager.add('text-left-image-right-block', {
      label: 'Imagen-Texto Derecha',
      content: `
        <section class="text-left-image-right-section">
          <div class="content-container">
            <div class="text-content">
              <h2 class="content-title">Título Informativo</h2>
              <p class="content-description">
                Esta sección es ideal para describir tus productos o servicios de forma clara y directa. Explica los detalles importantes y proporciona información que atraiga la atención del lector. Puedes utilizar este espacio para hablar sobre características, beneficios o valores de tu producto.
              </p>
              <a href="#more-info" class="content-cta">Más Información</a>
            </div>
            <div class="image-content">
              <img src="https://via.placeholder.com/400" alt="Descripción de la imagen" class="content-image">
            </div>
          </div>
        </section>
        <style>
          .text-left-image-right-section {
            padding: 60px 20px;
            font-family: 'Arial', sans-serif;
            background-color: #f4f4f4;
          }
          .content-container {
            display: flex;
            align-items: center;
            gap: 20px;
            max-width: 1000px;
            margin: 0 auto;
            text-align: left;
            flex-wrap: wrap;
          }
          .text-content {
            flex: 1;
            min-width: 280px;
          }
          .content-title {
            font-size: 32px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #333;
          }
          .content-description {
            font-size: 18px;
            line-height: 1.6;
            color: #555;
            margin-bottom: 20px;
          }
          .content-cta {
            display: inline-block;
            background-color: #007bff;
            color: #fff;
            padding: 12px 24px;
            text-decoration: none;
            font-weight: bold;
            border-radius: 50px;
            transition: background-color 0.3s ease, transform 0.3s ease;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
          }
          .content-cta:hover {
            background-color: #0056b3;
            transform: translateY(-3px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
          }
          .image-content {
            flex: 1;
            display: flex;
            justify-content: center;
            min-width: 280px;
          }
          .content-image {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
          }
          /* Responsive design */
          @media (max-width: 768px) {
            .content-container {
              flex-direction: column;
              text-align: center;
            }
            .text-content {
              text-align: center;
            }
            .content-title {
              font-size: 28px;
            }
            .content-description {
              font-size: 16px;
            }
          }
        </style>
      `,
      category: 'Imagen y Texto',
      attributes: { class: 'gjs-block-basic' },
    });
  };
  
  export default loadImageRight;
  