const loadImageBlock = (editor) => {
  editor.BlockManager.add('image-block', {
    label: 'Article Image',
    content: `
      <figure style="display: flex; flex-direction: column; align-items: center; margin: 40px 0; border: 1px solid #e2e8f0; border-radius: 10px; overflow: hidden; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);">
        <img src="https://via.placeholder.com/800x400" alt="Descripción de la Imagen" style="max-width: 100%; height: auto; display: block; border-radius: 10px 10px 0 0;">
        <figcaption style="font-size: 1em; color: #4a5568; margin: 15px; font-style: italic; text-align: center;">Pie de foto que explica la imagen mostrada</figcaption>
      </figure>
    `,
    category: 'Blog',
  });
};

export default loadImageBlock;
