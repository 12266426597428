const loadTitleImage = (editor) => {
    editor.BlockManager.add('title-subtitle-text-image-block', {
      label: 'Imagen-Título',
      content: `
        <section class="title-subtitle-text-image-section">
          <div class="title-subtitle-container">
            <h2 class="main-title">Título Principal</h2>
            <p class="main-subtitle">Este es el subtítulo que acompaña el título principal.</p>
          </div>
          <div class="content-container">
            <div class="text-content">
              <p class="description-text">
                Aquí puedes agregar una descripción detallada del producto o servicio. Explica los beneficios, características y cualquier otra información relevante que ayude al visitante a entender más sobre lo que estás ofreciendo. Este texto es lo suficientemente flexible para adaptarse a tus necesidades.
              </p>
            </div>
            <div class="image-content">
              <img src="https://via.placeholder.com/400" alt="Descripción de la imagen" class="content-image">
            </div>
          </div>
        </section>
        <style>
          .title-subtitle-text-image-section {
            padding: 60px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .title-subtitle-container {
            margin-bottom: 40px;
          }
          .main-title {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #333;
          }
          .main-subtitle {
            font-size: 20px;
            color: #555;
            margin-bottom: 20px;
          }
          .content-container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            max-width: 1000px;
            margin: 0 auto;
            text-align: left;
            flex-wrap: wrap;
          }
          .text-content {
            flex: 1;
            min-width: 280px;
          }
          .description-text {
            font-size: 18px;
            line-height: 1.6;
            color: #555;
          }
          .image-content {
            flex: 1;
            display: flex;
            justify-content: center;
            min-width: 280px;
          }
          .content-image {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
          }
          /* Responsive design */
          @media (max-width: 768px) {
            .content-container {
              flex-direction: column;
              text-align: center;
            }
            .text-content, .image-content {
              text-align: center;
            }
            .main-title {
              font-size: 30px;
            }
            .main-subtitle {
              font-size: 18px;
            }
          }
        </style>
      `,
      category: 'Imagen y Texto',
      attributes: { class: 'gjs-block-basic' },
    });
  };
  
  export default loadTitleImage;
  