import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LandingPageList() {
  const [landing, setLanding] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para indicar si está cargando
  const [timeoutReached, setTimeoutReached] = useState(false); // Estado para manejar el tiempo de espera
  // estados de paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 30; // Número de documentos por página

  // controles de paginación
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

    useEffect(() => {
      const timeout = setTimeout(() => {
        setTimeoutReached(true);
      }, 30000); // 30 segundos de espera
  
      fetch(`https://modelo-de-negocios-backend-180g.onrender.com/blogs?page=${currentPage}&limit=${limit}`)
        .then(response => {
          clearTimeout(timeout); // Limpiar el timeout si la respuesta llega a tiempo
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setIsLoading(false); // Detener la carga cuando los datos son recibidos
          setLanding(data.documents); // Establecer los documentos recibidos
          setTotalPages(data.totalPages); // Establecer el número total de páginas
        })
        .catch(error => {
          clearTimeout(timeout);
          console.log("Error fetching landing pages: ", error);
          setIsLoading(false); // Detener la carga incluso en caso de error
        });
    }, [currentPage]); // Agregar currentPage como dependencia

    const handleDelete = async (id) => {
      const confirmDelete = window.confirm("¿Estás seguro de eliminar este landing?");
      if (!confirmDelete) {
        return; // Cancelar la operación si el usuario no confirma
      }
  
      try {
        await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/blogs/${id}`, {
          method: 'DELETE'
        });
        setLanding(prevLanding => prevLanding.filter(landingItem => landingItem._id !== id));
      } catch (error) {
        console.log(error.message);
      }
    };

  return (
<section>
  <div className="max-w-screen-xl mx-auto px-4 mt-8">
    <h1 className="text-3xl font-bold mb-4">Lista de blogs</h1>

    
    {isLoading && !timeoutReached && (
      <div className="flex justify-center items-center mt-8">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"></div>
        <p className="ml-4 text-lg text-gray-600">Cargando blogs, por favor espera...</p>
      </div>
    )}

   
    {!isLoading && (
      <div className="overflow-x-auto">
        {landing.length > 0 ? (
          <table className="table-auto w-full border-collapse">
            <thead className="bg-gray-300">
              <tr>
                <th className="px-4 py-2">Fecha de creación</th>
                <th className="px-4 py-2">Título</th>
                {landing.some(landingItem => landingItem.description) && <th className="px-4 py-2">Descripción</th>}
                <th className="px-1 py-2">Editor</th>
                <th className="px-1 py-2">Eliminar</th>
                <th className="px-1 py-2">Editar</th>
                {landing.some(landingItem => landingItem.keywords) && <th className="px-1 py-2">Ver</th>}
              </tr>
            </thead>
            <tbody>
              {landing.map(landingItem => (
                <tr key={landingItem._id} className="border-t">
                  <td className="px-4 py-2 text-center">
                    {landingItem.createdAt 
                      ? new Date(landingItem.createdAt).toLocaleString("es-ES", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        })
                      : "Fecha no disponible"}
                  </td>
                  <td className="px-4 py-2">{landingItem.title || 'Borrador'}</td>
                  {landingItem.description && <td className="px-4 py-2">{landingItem.description}</td>}

                   {/* Nueva celda para mostrar quién realizó la última edición */}
                  <td className="px-4 py-2">{landingItem.editedBy || 'Desconocido'}</td>


                  <td className="px-4 py-2">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(landingItem._id)}
                    >
                      Eliminar
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    <Link
                      to={`/blogedit/${landingItem._id}`}  // Enlace para editar usando landingItem._id
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    >
                      Editar
                    </Link>
                  </td>
                  {landingItem.keywords && (
                    <td className="px-4 py-2">
                      <Link
                        to={`/blogs/${landingItem.keywords.replace(/\s/g, '-')}`}  // Enlace para ver usando keywords
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                      >
                        Ver
                      </Link>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-600 mt-8">No hay blogs disponibles o están incompletos.</p>
        )}

        {/* Controles de paginación */}
        <div className="pagination-controls flex justify-center items-center my-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2 ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Anterior
          </button>

          <span className="mx-4">Página {currentPage} de {totalPages}</span>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-2 ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            Siguiente
          </button>
        </div>
      </div>
    )}
  </div>
</section>
  );
}

export default LandingPageList;
