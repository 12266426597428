import loadHeaderBlock from './HeaderBlock';
import loadContentBlock from './ContentBlock';
import loadImageBlock from './ImageBlock';
import loadQuoteBlock from './QuoteBlock';
import loadSocialBlock from './SocialBlock';
import loadConclusionBlock from './CTA';
import loadinfoproductBlock from './Infoproducts/Infoproducts';
import loadMediaBlock from './loadMediaBlock';

const loadBlogBlocks = (editor) => {
  loadHeaderBlock(editor);
  loadContentBlock(editor);
  loadImageBlock(editor);
  loadQuoteBlock(editor);
  loadSocialBlock(editor);
  loadConclusionBlock(editor);
  loadinfoproductBlock(editor);
  loadMediaBlock(editor);
};

export default loadBlogBlocks;
