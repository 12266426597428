import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {storage} from '../../api/firebase.config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { UserAuth } from '../../context/AuthContext';

const MetadataForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    keywords: '',
    categorie: '',
    subcategorie: '',
    subsubcategorie: '',
    author: '',
    question1: '',
    answer1: '',
    question2: '',
    answer2: '',
    question3: '',
    answer3: '',
    imageUrl: '',
    imageRedirectUrl: '',
    infoproductImageUrl: '', // URL de la imagen del infoproducto
    infoproductRedirectUrl: '', // URL de redirección del infoproducto
    rightInfoproductImageUrl: '', 
    rightInfoproductRedirectUrl: '', 

  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/blogs-page/metadata/${id}`);
        if (!response.ok) {
          throw new Error('Error al obtener datos');
        }
        const data = await response.json();
        console.log("Datos recibidos:", data);
        setFormData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const imageRef = ref(storage, `Images/${file.name}`);
    try {
      const snapshot = await uploadBytes(imageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      setFormData({ ...formData, imageUrl: url });
    } catch (error) {
      console.error('Error al subir la imagen:', error);
    }
  };

    // Subir imagen del infoproducto y almacenar la URL en infoproductImageUrl
    const handleInfoproductImageUpload = async (e) => {
      const file = e.target.files[0];
      const infoproductImageRef = ref(storage, `InfoproductImages/${file.name}`);
      try {
        const snapshot = await uploadBytes(infoproductImageRef, file);
        const url = await getDownloadURL(snapshot.ref);
        setFormData({ ...formData, infoproductImageUrl: url }); // Guarda la URL de la imagen del infoproducto en formData
      } catch (error) {
        console.error('Error al subir la imagen del infoproducto:', error);
      }
    };

        // Subir imagen del infoproducto y almacenar la URL en infoproductImageUrl
        const handleRightInfoproductImageUpload = async (e) => {
          const file = e.target.files[0];
          const infoproductImageRef = ref(storage, `InfoproductImages/${file.name}`);
          try {
            const snapshot = await uploadBytes(infoproductImageRef, file);
            const url = await getDownloadURL(snapshot.ref);
            setFormData({ ...formData, rightInfoproductImageUrl: url }); // Guarda la URL de la imagen del infoproducto en formData
          } catch (error) {
            console.error('Error al subir la imagen del infoproducto:', error);
          }
        };

        const handleSubmit = async (e) => {
          e.preventDefault();
          try {
            // Crea un nuevo objeto que incluye los datos del formulario y del usuario
            const metadata = {
              ...formData,
              editedBy: user.email, // Incluye el email del usuario que edita
              editorId: user.uid // Incluye el UID del usuario
            };
        
            const response = await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/blogs-page/metadata/${id}`, {
              method: 'PUT', // Cambiamos a PUT para actualizar
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(metadata) // Envía el formulario con los datos del editor
            });
        
            if (!response.ok) {
              throw new Error('Error al enviar metadata');
            }
        
            const data = await response.json();
            console.log('Metadata enviado:', data);
            navigate(`/Administrator`);
          } catch (error) {
            console.error(error);
          }
        };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
    <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Formulario de Metadata</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-gray-700">Título:</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Descripción:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Palabras clave:</label>
          <input
            type="text"
            name="keywords"
            value={formData.keywords}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div className="space-y-4">
  <div>
    <label className="block text-gray-700">Categoría principal:</label>
    <select
      name="categorie"
      value={formData.categorie}
      onChange={handleChange}
      required
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
    >
      <option value="">Selecciona una categoría</option>
      <option value="Negocios">Negocios</option>
      <option value="Marketing">Marketing</option>
      <option value="SEO">SEO</option>
      <option value="Redes Sociales">Redes Sociales</option>
      <option value="E-commerce">E-commerce</option>
      <option value="Emprendimiento">Emprendimiento</option>
      <option value="Tecnología Empresarial">Tecnología Empresarial</option>
     
    </select>
  </div>
  
  <div>
    <label className="block text-gray-700">Subcategoría:</label>
    <select
      name="subcategorie"
      value={formData.subcategorie}
      onChange={handleChange}
      required
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
    >
      <option value="">Selecciona una subcategoría</option>
      <option value="Marketing de Contenidos">Marketing de Contenidos</option>
      <option value="Publicidad Digital">Publicidad Digital</option>
      <option value="Estrategia SEO">Estrategia SEO</option>
      <option value="Gestión de Redes Sociales">Gestión de Redes Sociales</option>
      <option value="Analítica Web">Analítica Web</option>
      <option value="Email Marketing">Email Marketing</option>
      <option value="Automatización de Marketing">Automatización de Marketing</option>
      
    </select>
  </div>
  
  <div>
    <label className="block text-gray-700">Sub-subcategoría:</label>
    <select
      name="subsubcategorie"
      value={formData.subsubcategorie}
      onChange={handleChange}
      required
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
    >
      <option value="">Selecciona una sub-subcategoría</option>
      <option value="SEO On-Page">SEO On-Page</option>
      <option value="SEO Off-Page">SEO Off-Page</option>
      <option value="Instagram Ads">Instagram Ads</option>
      <option value="Facebook Ads">Facebook Ads</option>
      <option value="LinkedIn Marketing">LinkedIn Marketing</option>
      <option value="Google Analytics">Google Analytics</option>
      <option value="Segmentación de Audiencias">Segmentación de Audiencias</option>
      
    </select>
  </div>
</div>
        <div>
          <label className="block text-gray-700">Autor:</label>
          <input
            type="text"
            name="author"
            value={formData.author}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Pregunta 1:</label>
          <input
            type="text"
            name="question1"
            value={formData.question1}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Respuesta 1:</label>
          <input
            type="text"
            name="answer1"
            value={formData.answer1}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Pregunta 2:</label>
          <input
            type="text"
            name="question2"
            value={formData.question2}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Respuesta 2:</label>
          <input
            type="text"
            name="answer2"
            value={formData.answer2}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Pregunta 3:</label>
          <input
            type="text"
            name="question3"
            value={formData.question3}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Respuesta 3:</label>
          <input
            type="text"
            name="answer3"
            value={formData.answer3}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">Imagen:</label>
          {/* Aviso permanente */}
          <p className="text-blue-500 mb-2 text-sm italic">
            Si la imagen ya está guardada, puedes dejar este campo vacío.
          </p>
          <input
            type="file"
            name="image"
            onChange={handleImageUpload}
            
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
        <div>
          <label className="block text-gray-700">URL de Redirección de la Imagen:</label>
          <input
            type="text"
            name="imageRedirectUrl"
            value={formData.imageRedirectUrl}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>

        {/* Campo de carga de imagen del infoproducto */}
        <div>
            <label className="block text-gray-700">Imagen del Infoproducto:</label>
             {/* Aviso permanente */}
            <p className="text-blue-500 mb-2 text-sm italic">
              Si la imagen ya está guardada, puedes dejar este campo vacío.
            </p>
            <input
              type="file"
              name="infoproductImage"
              onChange={handleInfoproductImageUpload} // Cargar imagen del infoproducto a Firebase
              
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>

          {/* URL de redirección del infoproducto */}
          <div>
            <label className="block text-gray-700">URL de Redirección del Infoproducto:</label>
            <input
              type="text"
              name="infoproductRedirectUrl"
              value={formData.infoproductRedirectUrl}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>

          {/* Campo de carga de imagen del infoproducto derecho */}
        <div>
            <label className="block text-gray-700">Imagen del Infoproducto:</label>
                {/* Aviso permanente */}
                <p className="text-blue-500 mb-2 text-sm italic">
                  Si la imagen ya está guardada, puedes dejar este campo vacío.
                </p>
            <input
              type="file"
              name="infoproductImage"
              onChange={handleRightInfoproductImageUpload} // Cargar imagen del infoproducto a Firebase
              
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>

          {/* URL de redirección del infoproducto derecho */}
          <div>
            <label className="block text-gray-700">URL de Redirección del Infoproducto:</label>
            <input
              type="text"
              name="rightInfoproductRedirectUrl"
              value={formData.rightInfoproductRedirectUrl}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600"
            />
          </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
        >
          Guardar Metadata
        </button>
      </form>
    </div>
  </div>
  );
};

export default MetadataForm;
