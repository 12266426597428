const loadInfoProductImageBlock = (editor) => {
    editor.BlockManager.add('infoproduct-image-block', {
      label: 'Infoproduct Image',
      content: `
        <section class="infoproduct-image-section">
          <div class="infoproduct-image-container">
            <a href="#" class="infoproduct-image-link">
              <img src="https://via.placeholder.com/600x400" alt="Infoproduct Image" class="infoproduct-image" />
            </a>
          </div>
          <a href="#" class="view-more-button">Ver más</a>
        </section>
        <style>
          .infoproduct-image-section {
            background-color: #fff;
            padding: 40px 20px;
            text-align: center;
            font-family: 'Arial', sans-serif;
          }
          .infoproduct-image-container {
            max-width: 1000px;
            margin: 0 auto;
          }
          .infoproduct-image-link {
            display: inline-block;
            text-decoration: none;
          }
          .infoproduct-image {
            max-width: 100%;
            height: auto;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease-in-out;
          }
          .infoproduct-image-link:hover .infoproduct-image {
            transform: scale(1.05);
          }
          .view-more-button {
            display: inline-block;
            margin-top: 20px;
            padding: 12px 24px;
            background-color: #28a745;
            color: #fff;
            text-decoration: none;
            border-radius: 6px;
            font-size: 18px;
            font-weight: bold;
            transition: background-color 0.3s ease;
          }
          .view-more-button:hover {
            background-color: #218838;
          }
          @media (max-width: 768px) {
            .infoproduct-image-section {
              padding: 20px 10px;
            }
            .infoproduct-image-container {
              width: 100%;
              max-width: 100%;
            }
            .view-more-button {
              font-size: 16px;
              padding: 10px 20px;
            }
          }
        </style>
      `,
      attributes: { class: 'gjs-fonts gjs-f-image' }
    });
  };
  
  export default loadInfoProductImageBlock;
  