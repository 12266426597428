const loadMediaBlock = (editor) => {
    editor.BlockManager.add('media-block', {
      label: 'Media (YouTube Video)',
      attributes: { class: 'fa fa-file-video' },
      content: `
        <div style="display: flex; flex-direction: column; align-items: center; margin: 40px 0;">
          <iframe 
            width="100%" 
            height="315" 
            src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen 
            style="border-radius: 10px; margin-bottom: 15px;">
          </iframe>
          <figcaption style="font-size: 1em; color: #4a5568; margin-top: 10px; text-align: center;">
            Pie de foto que explica el video mostrado
          </figcaption>
        </div>
      `,
    });
  };
  
  export default loadMediaBlock;
  