import { useEffect } from "react";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export function LoginUser() {
    const navigate = useNavigate();
    const { user, googleSignIn } = UserAuth();

    const iniciarSesion = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user && user.email) {
            const redirectUrl = localStorage.getItem('redirectUrl');
            
            if (redirectUrl) {
                navigate(redirectUrl);
                localStorage.removeItem('redirectUrl');
            } else {
                navigate("/blog/");
            }
        }
    }, [user, navigate]);

    return (
        <div className="max-w-md mx-auto mt-12 bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-center text-gray-800 dark:text-white">Iniciar Sesión</h2>
        <p className="text-sm text-center text-gray-500 dark:text-gray-400 mt-1">Por favor ingresa tus datos</p>
        <form className="mt-6">
            <div className="mb-4">
                <label
                    htmlFor="username"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                    Usuario
                </label>
                <input
                    type="text"
                    id="username"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                />
            </div>
    
            <div className="mb-6">
                <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                    Contraseña
                </label>
                <input
                    type="password"
                    id="password"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-gray-100 border border-gray-300 rounded-lg dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-300 focus:outline-none"
                />
            </div>
    
            <button className="w-full px-4 py-2 font-semibold text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring focus:ring-blue-300 focus:ring-opacity-50 transition duration-300">
                Iniciar sesión
            </button>
    
            <div className="flex items-center justify-center mt-6">
                <button
                    onClick={iniciarSesion}
                    type="button"
                    className="flex items-center justify-center w-full px-4 py-2 font-semibold text-white bg-red-500 rounded-lg shadow-md hover:bg-red-600 focus:ring focus:ring-red-300 focus:ring-opacity-50 transition duration-300"
                >
                    <svg className="w-5 h-5 fill-current" viewBox="0 0 24 24">
                        <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"></path>
                    </svg>
                    <span className="ml-2">Iniciar sesión con Google</span>
                </button>
            </div>
        </form>
    </div>
    );
}

export default LoginUser;
